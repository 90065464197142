import gql from 'graphql-tag';

export const PagesInfoFragment = gql`
	fragment PagesInfoFragment on PTPage {
		id
		title
		status
		hasChildren
		blank
		hasRestrictions @include(if: $isSuperAdmin)
		hasInheritedRestrictions @include(if: $isSuperAdmin)
		type
		links {
			webui
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
	}
`;
